.table thead tr th {
  border-bottom: 2px solid #e7ecf1 !important;
  font-weight: 500;
}

.table> :not(:first-child) {
  border-top: 0 !important;
}

.form-control[readonly] {
  background-color: #eff2f5;
}

.col-form-label {
  font-weight: 400;
}

.svg-icon.svg-icon-lg svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}

.react-select-4-listbox {
  width: 300px !important;
  position: absolute !important;
}

/*timeline style */
.timeline.timeline-3 .timeline-items {
  margin: 0;
  padding: 0;
}

.timeline.timeline-3 .timeline-items .timeline-item {
  margin-left: 25px;
  border-left: 2px solid #e7ecf1;
  padding: 0 0 20px 50px;
  position: relative;
}

.timeline.timeline-3 .timeline-items .timeline-item:last-child {
  border-left-color: transparent;
  padding-bottom: 0;
}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media {
  position: absolute;
  top: 0;
  left: -26px;
  border: 2px solid #e7ecf1;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fa;
  line-height: 0;
}